import React from 'react';
import { BasicElementT, ElementTypeT } from '../../../../types/Presentation.ts';
import { Interaction } from '../../../interaction/components/Interaction';
import { Visualization } from '../../../visualization/components/Visualization';
import { StaticContent } from '../../../staticContent/components/StaticContent';

interface Props {
	presentationId: string;
	element: BasicElementT;
}

export const Element: React.FC<Props> = ({ presentationId, element }) => {
	const elementType = element.elementType;
	if (elementType === ElementTypeT.INTERACTION) {
		return <Interaction presentationId={presentationId} element={element} />;
	} else if (elementType === ElementTypeT.VISUALIZATION) {
		return <Visualization presentationId={presentationId} element={element} />;
	} else if (elementType === ElementTypeT.STATIC_CONTENT) {
		return <StaticContent presentationId={presentationId} element={element} />;
	} else {
		return null;
	}
};
