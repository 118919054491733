import React from 'react';
import { useAllThemes } from '../../hooks/useAllThemes.ts';
import { Loading } from '../../../commons/components/Loading';
import { Alert, AlertTitle, Icon, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { deleteThemeById } from '../../service/ThemingService.ts';
import { ThemeT } from '../../model/ThemeT.ts';
import { translate } from '../../../../translate';

interface Props {
	onEdit: (themeId: ThemeT['id']) => void;
}

export const ThemeList: React.FC<Props> = ({ onEdit }) => {
	const { isPending, data, isError, refetch } = useAllThemes();

	const handleOnEdit = (themeId: ThemeT['id']) => () => {
		onEdit(themeId);
	};

	const handleDelete = (themeId: ThemeT['id']) => async () => {
		await deleteThemeById(themeId);
		await refetch();
	};

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return (
			<Alert status='error'>
				<AlertTitle>{translate('Error fetching themes')}</AlertTitle>
			</Alert>
		);
	}

	if (!data) {
		return (
			<>
				<Text>{translate('No themes yet')}</Text>
				<Text>{translate('Create a new theme to start')}</Text>
			</>
		);
	}

	return (
		<TableContainer
			width='full'
			maxH='74vh'
			overflowY='auto'
			sx={{
				'::-webkit-scrollbar': {
					display: 'none',
				},
			}}
		>
			<Table>
				<Thead sx={{ position: 'sticky', top: 0 }}>
					<Tr>
						<Th fontSize='lg'>{translate('Themes')}</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((value) => (
						<Tr key={value.id} _hover={{ cursor: 'default' }}>
							<Td _hover={{ cursor: 'pointer' }} onClick={handleOnEdit(value.id)}>
								{value.label}
							</Td>
							<Td>
								<Menu variant='simple'>
									<MenuButton>
										<Icon as={BiDotsHorizontalRounded} fontSize='xl' />
									</MenuButton>
									<MenuList fontSize='xl'>
										<MenuItem onClick={handleOnEdit(value.id)} icon={<AiOutlineEdit />}>
											{translate('Edit')}
										</MenuItem>
										<MenuItem onClick={handleDelete(value.id)} icon={<AiOutlineDelete />} color='red'>
											{translate('Delete')}
										</MenuItem>
									</MenuList>
								</Menu>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
