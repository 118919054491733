import ApiClient from '../../../apiClient';
import { BasicPresentationT, PresentationListT } from '../../../types/Presentation.ts';

export const getPresentationList = async (query: string): Promise<PresentationListT[]> => {
	return (await ApiClient.getInstance().get<PresentationListT[]>(`/author-app/presentations?q=${query}`)).data;
};

export const getPresentationById = async (presentationId: string): Promise<BasicPresentationT> => {
	return (await ApiClient.getInstance().get<BasicPresentationT>(`/author-app/presentations/${presentationId}`)).data;
};

export const createPresentation = async (title: string, description: string): Promise<BasicPresentationT> => {
	return (
		await ApiClient.getInstance().post(`/author-app/presentations`, {
			title,
			description,
		})
	).data;
};

export const updatePresentationTitle = async (presentationId: string, title: string): Promise<void> => {
	await ApiClient.getInstance().patch(`/author-app/presentations/${presentationId}`, {
		title,
	});
};

export interface UpdatePresentationSettingsInput {
	hideProgressBar: boolean;
	hideControls: boolean;
}

export const updatePresentationSettings = async (presentationId: string, input: UpdatePresentationSettingsInput): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/settings`, input);
};

export const deletePresentation = async (presentationId: string): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}`);
};

interface DuplicatePresentationOutput {
	presentationId: string;
}

export const duplicatePresentation = async (presentationId: string): Promise<DuplicatePresentationOutput> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/duplicate`, {})).data;
};

//===========================

export interface UpdateSlideOrder {
	slideId: string;
	newIndex: number;
}

export const updatePresentationSlidesOrder = async (presentationId: string, updateSlideOrders: UpdateSlideOrder[]): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/sort-slides`, updateSlideOrders);
};

interface UpdateSlideImageBackground {
	imageBackgroundUrl?: string;
}

export const updateSlideImageBackground = async (presentationId: string, slideId: string, input: UpdateSlideImageBackground): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/slides/${slideId}/imageBackground`, { ...input });
};

interface UpdateSlideVisibility {
	isHidden: boolean;
}

export const updateSlideVisibility = async (presentationId: string, slideId: string, input: UpdateSlideVisibility): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/slides/${slideId}/visibility`, { ...input });
};

interface UpdateSlideTitleVisibility {
	hideTitle: boolean;
}

export const updateSlideTitleVisibility = async (presentationId: string, slideId: string, input: UpdateSlideTitleVisibility): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/slides/${slideId}/title-visibility`, { ...input });
};
