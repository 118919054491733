import React from 'react';
import { EntryListResponse } from '../../service/EntryService.ts';
import { Flex, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { InteractionT, InteractionTypeT } from '../../../../types/Interaction.ts';
import { CategorisedTableRow } from './_/CategorisedTableRow.tsx';
import { NumberTableRow } from './_/NumberTableRow.tsx';
import { OpenEndedTableRow } from './_/OpenEndedTableRow.tsx';
import { IconicTableRow } from './_/IconicTableRow.tsx';
import { ValuationTableRow } from './_/ValuationTableRow.tsx';
import { ImageTableRow } from './_/ImageTableRow.tsx';
import { DrawingTableRow } from './_/DrawingTableRow.tsx';
import { LinkTableRow } from './_/LinkTableRow.tsx';
import { CategorisedEntry, DrawingEntry, IconicEntry, ImageEntry, LinkEntry, NumberEntry, OpenEndedEntry, OpenEndedLongEntry, ValuationEntry } from '../../model/entry.ts';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import isEmpty from 'lodash/isEmpty';
import { translate } from '../../../../translate';
import { OpenEndedLongTableRow } from './_/OpenEndedLongTableRow.tsx';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entry: EntryListResponse;
}

export const EntryListTable: React.FC<Props> = ({ presentationId, interactionId, entry }) => {
	return (
		<TableContainer overflowY='scroll' maxH='55svh'>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th width='16'>Show</Th>
						<Th>Response</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{!isEmpty(entry.entries) ? (
						<>
							{entry.interaction.type === InteractionTypeT.NUMBER ? (
								<NumberTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as NumberEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.OPEN_ENDED ? (
								<OpenEndedTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as OpenEndedEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.OPEN_ENDED_LONG ? (
								<OpenEndedLongTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as OpenEndedLongEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.MULTIPLE_CHOICE ? (
								<OpenEndedTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as OpenEndedEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.ICONIC ? (
								<IconicTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as IconicEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.CATEGORISED ? (
								<CategorisedTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as CategorisedEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.VALUATION ? (
								<ValuationTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as ValuationEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.IMAGE ? (
								<ImageTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as ImageEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.DRAWING ? (
								<DrawingTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as DrawingEntry[]} />
							) : null}
							{entry.interaction.type === InteractionTypeT.LINK ? (
								<LinkTableRow presentationId={presentationId} interactionId={interactionId} entries={entry.entries as LinkEntry[]} />
							) : null}
						</>
					) : null}
				</Tbody>
			</Table>
			{isEmpty(entry.entries) ? (
				<Flex justifyContent='center'>
					<Heading size='sm'>{translate('There are no entries to moderate yet')}</Heading>
				</Flex>
			) : null}
		</TableContainer>
	);
};
