import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import ApiClient from '../../../apiClient';

interface AuthContextType {
	isAuthenticated: boolean;
	isLoading: boolean;
	currentUser: User | null;
	login: (email: string, password: string) => Promise<void>;
	resetPassword: (email: string) => Promise<void>;
	logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
	isAuthenticated: false,
	isLoading: true, // Initially true, as we're unsure of the user's auth state
	currentUser: null,
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	resetPassword: () => Promise.resolve(),
});

export const useAuth = () => useContext(AuthContext);

interface Props {
	auth: Auth;
	children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<Props> = ({ auth, children }) => {
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	onAuthStateChanged(auth, (user) => {
		console.log('Firebase user login change');
		// console.log('user state changed', user);
		setCurrentUser(user);
		setIsLoading(false);
	});

	useEffect(() => {
		if (currentUser) {
			console.log('user logged in. configuring token generator for api');
			ApiClient.getInstance().withTokenGenerator(() => currentUser.getIdToken());
		} else {
			console.log('user logged out. removing token generator for api');
			ApiClient.getInstance().withTokenGenerator(() => Promise.resolve(''));
		}
	}, [currentUser]);

	const login = useCallback(
		async (username: string, password: string) => {
			await signInWithEmailAndPassword(auth, username, password);
		},
		[auth]
	);

	const resetPassword = useCallback(
		async (email: string) => {
			await sendPasswordResetEmail(auth, email, {
				url: window.location.origin, // Ensure this action's security rules are properly configured in Firebase
			});
		},
		[auth]
	);

	const logout = useCallback(async () => {
		await signOut(auth);
	}, [auth]);

	const contextValue: AuthContextType = useMemo(
		() => ({
			isAuthenticated: !!currentUser,
			currentUser,
			isLoading,
			login,
			resetPassword,
			logout,
		}),
		[currentUser, isLoading, login, resetPassword, logout]
	);

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
