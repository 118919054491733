import { PresentationListT } from '../../../../../../../../types/Presentation.ts';
import isEmpty from 'lodash/isEmpty';
import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { translate } from '../../../../../../../../translate';

interface Props {
	presentations: PresentationListT[];
	onPresentationClick: (presentationId: string) => void;
}

export const PresentationTable = ({ presentations, onPresentationClick }: Props) => {
	const handleOnClick = (presentationId: string) => () => {
		onPresentationClick(presentationId);
	};

	return (
		<>
			{isEmpty(presentations) ? (
				<Flex flexDirection='column' alignItems='center'>
					<Text fontSize='xl' fontWeight='bold'>
						There aren&apos;t any Presentations
					</Text>
				</Flex>
			) : null}
			{!isEmpty(presentations) ? (
				<>
					<TableContainer
						width='full'
						maxH='74vh'
						overflowY='auto'
						sx={{
							'::-webkit-scrollbar': {
								display: 'none',
							},
						}}
					>
						<Table>
							<Thead sx={{ position: 'sticky', top: 0 }}>
								<Tr>
									<Th>{translate('Title')}</Th>
									<Th>{translate('Last modified')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{presentations.map((presentation) => (
									<Tr key={presentation.id}>
										<Td onClick={handleOnClick(presentation.id)}>
											<Text>{presentation.title}</Text>
										</Td>
										<Td onClick={handleOnClick(presentation.id)}>
											<Text>{presentation.lastModifiedDate.toString()}</Text>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</>
			) : null}
		</>
	);
};
