import React, { useState } from 'react';
import { Button, Container, Flex, useDisclosure, VStack } from '@chakra-ui/react';
import { ThemeList } from '../../components/ThemeList';
import { HiPlus } from 'react-icons/hi';
import { EditTheme } from '../../components/EditTheme';
import { CreateTheme } from '../../components/CreateTheme';
import { ThemeT } from '../../model/ThemeT.ts';
import { translate } from '../../../../translate';

interface Props {}

export const ThemeListPage: React.FC<Props> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [themeIdToEdit, setThemeIdToEdit] = useState<ThemeT['id']>();

	const handleOnEdit = (themeId: ThemeT['id']) => {
		setThemeIdToEdit(themeId);
		onOpen();
	};

	const handleOnCreate = () => {
		setThemeIdToEdit(undefined);
		onOpen();
	};

	return (
		<Container>
			<Flex flexDirection='column'>
				{themeIdToEdit && isOpen ? <EditTheme themeId={themeIdToEdit} onClose={onClose} /> : null}
				{!themeIdToEdit && isOpen ? <CreateTheme onClose={onClose} /> : null}
				<Flex alignSelf='flex-end'>
					<VStack>
						<Button rightIcon={<HiPlus />} onClick={handleOnCreate} variant='submit'>
							{translate('New theme')}
						</Button>
					</VStack>
				</Flex>
				<Flex>
					<ThemeList onEdit={handleOnEdit} />
				</Flex>
			</Flex>
		</Container>
	);
};
