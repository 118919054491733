import React, { useEffect, useState } from 'react';
import { ElementSlideT } from '../../../../../../types/Slide.ts';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { updateSlideVisibility } from '../../../../service/PresentationService.ts';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../../../types/Constants.ts';
import { translate } from '../../../../../../translate';

interface Props {
	presentationId: string;
	slide: ElementSlideT;
}

export const HideSlideInput: React.FC<Props> = ({ presentationId, slide }: Props) => {
	const [isHidden, setIsHidden] = useState(slide.hidden);
	const doUpdate = async () => {
		await updateSlideVisibility(presentationId, slide.id, { isHidden: isHidden });
	};

	const debouncedChangeHandler = debounce(doUpdate, AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		setIsHidden(slide.hidden);
	}, [slide.hidden]);

	useEffect(() => {
		if (slide.hidden !== isHidden) {
			debouncedChangeHandler();
		}
	}, [isHidden]);

	const handleOnChange = async () => {
		setIsHidden((prevState) => !prevState);
	};

	return (
		<FormControl display='flex' alignItems='center'>
			<Switch size='lg' id={`HiddenSlideInput/${slide.id}`} onChange={handleOnChange} isChecked={isHidden} />
			<FormLabel htmlFor={`HiddenSlideInput/${slide.id}`} fontSize='lg' mx={2}>
				{translate('Hide slide')}
			</FormLabel>
		</FormControl>
	);
};
