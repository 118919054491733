import ApiClient from '../../../apiClient';
import {
	CategorisedConfigurationT,
	DrawingInteractionConfigurationT,
	IconicInteractionConfigurationT,
	ImageInteractionConfigurationT,
	InteractionT,
	InteractionTypeT,
	LinkInteractionConfigurationT,
	MultipleChoiceConfigurationT,
	NumberInteractionConfigurationT,
	OpenEndedInteractionConfigurationT,
	ValuationConfigurationT,
} from '../../../types/Interaction.ts';
import { VisualizationTypeT } from '../../../types/Visualization.ts';

export interface SearchInteractionResult {
	id: string;
	title: string;
	type: InteractionTypeT;
	lastModifiedDate: Date;
	presentationId: string;
	presentationTitle: string;
	supportedVisualizations: VisualizationTypeT[];
}

export const searchInteraction = async (presentationId: string, query: string): Promise<SearchInteractionResult[]> => {
	return (await ApiClient.getInstance().get<SearchInteractionResult[]>(`/author-app/presentations/${presentationId}/interactions?q=${query}`)).data;
};

// -----

export const getInteractionDetails = async (presentationId: string, interactionId: string): Promise<InteractionT> => {
	return (await ApiClient.getInstance().get<InteractionT>(`/author-app/presentations/${presentationId}/interactions/${interactionId}`)).data;
};

// -----

export const saveNumberInteraction = async (presentationId: string, interactionId: string, interaction: NumberInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/number`, interaction)).data;
};

// -----

export const saveOpenEndedInteraction = async (presentationId: string, interactionId: string, interaction: OpenEndedInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/open-ended`, interaction)).data;
};

// -----

export const saveOpenEndedLongInteraction = async (presentationId: string, interactionId: string, interaction: OpenEndedInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/open-ended-long`, interaction)).data;
};

// -----

export const saveMultipleChoiceInteraction = async (presentationId: string, interactionId: string, interaction: MultipleChoiceConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/multiple-choice`, interaction)).data;
};

export const saveCategorisedAnswerInteraction = async (presentationId: string, interactionId: string, interaction: CategorisedConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/categorised`, interaction)).data;
};

// -----

export const saveValuationInteraction = async (presentationId: string, interactionId: string, interaction: ValuationConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/valuation`, interaction)).data;
};

// -----

export const saveIconicInteraction = async (presentationId: string, interactionId: string, interaction: IconicInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/iconic`, interaction)).data;
};

// -----

export const saveImageInteraction = async (presentationId: string, interactionId: string, interaction: ImageInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/image`, interaction)).data;
};

// -----

export const saveDrawingInteraction = async (presentationId: string, interactionId: string, interaction: DrawingInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/drawing`, interaction)).data;
};

// -----

export const deleteInteraction = async (presentationId: string, interactionId: string): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/interactions/${interactionId}`);
};

// -----

export const saveLinkInteraction = async (presentationId: string, interactionId: string, interaction: LinkInteractionConfigurationT): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/interactions/${interactionId}/link`, interaction)).data;
};
