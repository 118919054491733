import { BorderProps, Box, Button, Flex, FlexboxProps, IconButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { PresentationTitle } from '../../../presentation/components/PresentationTitle';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { PresentationSettings } from '../../../presentation/components/PresentationSettings';
import { translate } from '../../../../translate';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { EmbedPresentation } from '../../../presentation/components/EmbedPresentation';

interface Props {
	presentationId: BasicPresentationT['id'];
	presentationTitle?: BasicPresentationT['title'];
	onViewPresentationInFrontend: () => void;
}

export const TopNavigation: React.FC<Props & FlexboxProps & BorderProps> = ({ presentationId, presentationTitle, onViewPresentationInFrontend }) => {
	return (
		<Box as='nav'>
			{/* <Container margin={0} maxW="full" alignContent="center"> */}
			<Flex justifyContent='space-between' alignItems='center' height={20}>
				<Flex width='80%' alignItems='center' mx={2}>
					<IconButton
						fontSize='32'
						backgroundColor='transparent'
						textColor='brand'
						aria-label=''
						borderRightRadius='0'
						my={0}
						mr={1}
						icon={<MdOutlineArrowBackIos />}
						as={RouterLink}
						to='/presentations'
					/>
					<PresentationTitle presentationId={presentationId} currentTitle={presentationTitle ?? 'Untitled'} />
				</Flex>
				<PresentationSettings presentationId={presentationId} />
				<EmbedPresentation presentationId={presentationId} presentationTitle={presentationTitle ?? 'Untitled'} />
				<Flex alignItems='center'>
					<Button rightIcon={<FiExternalLink />} variant='ghost' onClick={onViewPresentationInFrontend}>
						{translate('View presentation')}
					</Button>
				</Flex>
			</Flex>
			{/* </Container> */}
		</Box>
	);
};
