import { ElementSlideT } from './Slide.ts';

export interface StaticContentT {
	id: string;
	title: string;
	description: string;
	content: string;
	type: StaticContentTypeT;
	slide: ElementSlideT;
}

export enum StaticContentTypeT {
	HTML = 'HTML',
}
