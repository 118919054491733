import React, { ChangeEvent } from 'react';
import { useAllThemes } from '../../hooks/useAllThemes.ts';
import { Flex, Icon, Link as ChakraLink, Select, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { assignThemeToPresentation, unassignThemeToPresentation } from '../../service/ThemingService.ts';
import { refreshElementPreview } from '../../../presentation/components/ElementPreview/ElementPreview.tsx';
import { useThemeForPresentation } from '../../hooks/useThemeForPresentation.ts';
import { BiLinkExternal } from 'react-icons/bi';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
}

export const ThemePicker: React.FC<Props> = ({ presentationId }) => {
	const { isPending, data, isError } = useAllThemes();
	const { data: currentTheme, refetch } = useThemeForPresentation(presentationId);

	const handleSelect = async (evt: ChangeEvent<HTMLSelectElement>) => {
		try {
			const value = +evt.target.value;
			console.log('value', value);
			if (value > 0) {
				await assignThemeToPresentation(value, presentationId);
			} else {
				await unassignThemeToPresentation(presentationId);
			}
			await refetch(); //Refresh selected theme
			refreshElementPreview();
		} catch (e) {
			console.error('Error while trying to assign theme to presentation', e);
		}
	};

	console.log('current', currentTheme);

	return (
		<Flex alignItems='center' gap={6}>
			<Flex flexBasis='60%'>
				<Flex flexDirection='column'>
					<Text pb={1}>{translate('Selected theme')}</Text>
					<Select placeholder={isPending ? 'Loading themes...' : ''} isInvalid={isError} onChange={handleSelect} value={currentTheme ? currentTheme.id : -1}>
						<>
							<option key='empty' value={-1}>
								{translate('Default theme')}
							</option>
							{data
								? data.map((themeItem) => (
										<option key={themeItem.id} value={themeItem.id}>
											{themeItem.label}
										</option>
									))
								: null}
						</>
					</Select>
				</Flex>
			</Flex>
			<Flex flexBasis='50%' gap={1}>
				<ChakraLink as={ReactRouterLink} to='/themes' replace={false} target='_blank'>
					{translate('Manage your themes')}{' '}
				</ChakraLink>
				<Icon as={BiLinkExternal} />
			</Flex>
		</Flex>
	);
};
