import React, { ChangeEvent, useState } from 'react';
import { usePresentationList } from '../../hooks/usePresentationList.ts';
import { Flex, Heading, Icon, Input, InputGroup, InputLeftElement, VStack } from '@chakra-ui/react';
import { PresentationListTable } from './_/PresentationListTable';
import { CreatePresentationButton } from '../CreatePresentationButton';
import { FiSearch } from 'react-icons/fi';
import { DefaultMenu } from '../DefaultMenu';
import { Loading } from '../../../commons/components/Loading';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import { translate } from '../../../../translate';

interface Props {}

export const PresentationList: React.FC<Props> = () => {
	const [inputValue, setInputValue] = useState('');
	const { isLoading, data, refetch } = usePresentationList(inputValue);

	const handleOnAfterDeletion = async () => {
		await refetch();
	};

	const handleOnInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};
	const debouncedInputChangeHandler = debounce(handleOnInputChange, AUTO_SAVE_DEBOUNCE_TIME);

	return (
		<>
			<DefaultMenu />
			<VStack mx={16}>
				<Flex width='100%' alignItems='center' justifyContent='space-around' flexWrap='wrap'>
					<Heading size='xs'>{translate('Your presentations')}</Heading>
					<Flex flexBasis={{ base: '100%', lg: '60%' }}>
						<Flex alignSelf='center' mb='6' width='100%'>
							<InputGroup>
								<InputLeftElement>
									<Icon as={FiSearch} />
								</InputLeftElement>
								<Input
									id='Presentation/Search'
									onChange={debouncedInputChangeHandler}
									borderBottomRadius={0}
									size='lg'
									placeholder='Search'
									_hover={{ borderColor: 'light' }}
									_focus={{ borderColor: 'light' }}
								/>
							</InputGroup>
						</Flex>
					</Flex>
					<CreatePresentationButton />
				</Flex>
				<Flex width='100%' alignItems='center' justifyContent='center'>
					{isLoading ? <Loading /> : null}
					{!isLoading ? <PresentationListTable presentations={data || []} onAfterDeletion={handleOnAfterDeletion} /> : null}
				</Flex>
			</VStack>
		</>
	);
};
