import { Editor } from '@tiptap/react';
import React, { useCallback, useState } from 'react';
import { Box, Flex, IconButton, Select } from '@chakra-ui/react';
import {
	MdAddLink,
	MdArrowDropDown,
	MdFormatAlignCenter,
	MdFormatAlignJustify,
	MdFormatAlignLeft,
	MdFormatAlignRight,
	MdFormatBold,
	MdFormatItalic,
	MdFormatListBulleted,
	MdFormatListNumbered,
	MdFormatQuote,
	MdImage,
	MdLinkOff,
} from 'react-icons/md';
import { BsParagraph } from 'react-icons/bs';
import { BiCodeBlock } from 'react-icons/bi';
import { GrReturn } from 'react-icons/gr';

interface Props {
	editor: Editor;
}

export const TipTapMenuItems = ({ editor }: Props) => {
	return (
		<Flex flexDirection='row' alignItems='center' gap={2}>
			<Headings editor={editor} />
			<Paragraph editor={editor} />
			<Hardbreak editor={editor} />
			<AddImage editor={editor} />
			<Bold editor={editor} />
			<Italic editor={editor} />
			<BulletList editor={editor} />
			<OrderedList editor={editor} />
			<Codeblock editor={editor} />
			<Blockquote editor={editor} />
			<Link editor={editor} />
			<TextAlign editor={editor} />
		</Flex>
	);
};

interface CommonProps {
	editor: Editor;
}

const AddImage = ({ editor }: CommonProps) => {
	const addImage = useCallback(() => {
		const url = window.prompt('URL');
		if (url) {
			editor?.chain().focus().setImage({ src: url }).run();
		}
	}, [editor]);

	return <IconButton onClick={addImage} color='' aria-label='Add image' colorScheme='colorScheme' icon={<MdImage />} m={0} />;
};

const Bold = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleBold().run()}
			disabled={!editor.can().chain().focus().toggleBold().run()}
			className={editor.isActive('bold') ? 'is-active' : ''}
			color=''
			aria-label='Bold'
			colorScheme='colorScheme'
			m={0}
			icon={<MdFormatBold />}
		/>
	);
};

const Italic = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleItalic().run()}
			disabled={!editor.can().chain().focus().toggleItalic().run()}
			className={editor.isActive('italic') ? 'is-active' : ''}
			color=''
			aria-label='Italic'
			colorScheme='colorScheme'
			icon={<MdFormatItalic />}
			m={0}
		/>
	);
};

const Hardbreak = ({ editor }: CommonProps) => {
	return <IconButton onClick={() => editor.chain().focus().setHardBreak().run()} color='' aria-label='Hard break' colorScheme='colorScheme' icon={<GrReturn />} m={0} />;
};

const BulletList = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleBulletList().run()}
			className={editor.isActive('bulletList') ? 'is-active' : ''}
			color=''
			aria-label='Bulleted list'
			colorScheme='colorScheme'
			icon={<MdFormatListBulleted />}
			m={0}
		/>
	);
};

const OrderedList = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleOrderedList().run()}
			className={editor.isActive('orderedList') ? 'is-active' : ''}
			color=''
			aria-label='Ordered list'
			colorScheme='colorScheme'
			icon={<MdFormatListNumbered />}
			m={0}
		/>
	);
};

const Codeblock = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleCodeBlock().run()}
			className={editor.isActive('codeBlock') ? 'is-active' : ''}
			color=''
			aria-label='Code block'
			colorScheme='colorScheme'
			icon={<BiCodeBlock />}
			m={0}
		/>
	);
};

const Blockquote = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().toggleBlockquote().run()}
			className={editor.isActive('blockquote') ? 'is-active' : ''}
			color=''
			aria-label='Quote'
			colorScheme='colorScheme'
			icon={<MdFormatQuote />}
			m={0}
		/>
	);
};

const Link = ({ editor }: CommonProps) => {
	const setLink = useCallback(() => {
		const previousUrl = editor?.getAttributes('link').href;
		const url = window.prompt('URL', previousUrl);

		// cancelled
		if (url === null) {
			return;
		}

		// empty
		if (url === '') {
			editor?.chain().focus().extendMarkRange('link').unsetLink().run();

			return;
		}

		// update link
		editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
	}, [editor]);

	const isActive = editor.isActive('link');

	return (
		<>
			{!isActive ? (
				<IconButton onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''} color='' aria-label='Add link' colorScheme='colorScheme' icon={<MdAddLink />} m={0} />
			) : (
				<IconButton
					onClick={setLink}
					className={editor.isActive('link') ? 'is-active' : ''}
					color=''
					aria-label='Remove link'
					colorScheme='colorScheme'
					icon={<MdLinkOff />}
					m={0}
				/>
			)}
		</>
	);
};

const Paragraph = ({ editor }: CommonProps) => {
	return (
		<IconButton
			onClick={() => editor.chain().focus().setParagraph().run()}
			className={editor.isActive('paragraph') ? 'is-active' : ''}
			color=''
			aria-label='Paragraph'
			colorScheme='colorScheme'
			icon={<BsParagraph />}
			m={0}
		/>
	);
};

const Headings = ({ editor }: CommonProps) => {
	const [currentValue, setCurrentValue] = useState('');
	const handleHeadingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const level = parseInt(event.target.value) as 1 | 2 | 3 | 4 | 5 | 6; // Tip tap requires Level to be this union type but it doesn't export it
		editor.chain().focus().toggleHeading({ level }).run();
		setCurrentValue('');
	};

	return (
		<Box>
			<Select placeholder='Headings' icon={<MdArrowDropDown />} onChange={handleHeadingChange} value={currentValue}>
				<option value='1' className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
					h1
				</option>
				<option value='2' className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
					h2
				</option>
				<option value='3' className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
					h3
				</option>
				<option value='4' className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}>
					h4
				</option>
				<option value='5' className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}>
					h5
				</option>
				<option value='6' className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}>
					h6
				</option>
			</Select>
		</Box>
	);
};

const TextAlign = ({ editor }: CommonProps) => {
	return (
		<>
			<IconButton
				onClick={() => editor.chain().focus().setTextAlign('left').run()}
				className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
				color=''
				aria-label='Align left'
				colorScheme='colorScheme'
				icon={<MdFormatAlignLeft />}
				m={0}
			/>
			<IconButton
				onClick={() => editor.chain().focus().setTextAlign('center').run()}
				className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
				color=''
				aria-label='Align center'
				colorScheme='colorScheme'
				icon={<MdFormatAlignCenter />}
				m={0}
			/>
			<IconButton
				onClick={() => editor.chain().focus().setTextAlign('right').run()}
				className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
				color=''
				aria-label='Align right'
				colorScheme='colorScheme'
				icon={<MdFormatAlignRight />}
				m={0}
			/>
			<IconButton
				onClick={() => editor.chain().focus().setTextAlign('justify').run()}
				className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
				color=''
				aria-label='Justify'
				colorScheme='colorScheme'
				icon={<MdFormatAlignJustify />}
				m={0}
			/>
		</>
	);
};
