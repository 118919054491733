import React from 'react';
import { BasicElementT } from '../../../../types/Presentation.ts';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';
import { useStaticContent } from '../../hooks/useStaticContent.ts';
import { StaticContentHtml } from '../StaticContentHtml';
import { Loading } from '../../../commons/components/Loading';

interface Props {
	presentationId: string;
	element: BasicElementT;
}

export const StaticContent: React.FC<Props> = ({ presentationId, element }) => {
	const configurationType = element.type as StaticContentTypeT;
	const { isLoading, data } = useStaticContent(presentationId, element.id);

	if (isLoading && !data) {
		return <Loading />;
	}

	if (data && configurationType === StaticContentTypeT.HTML) {
		return <StaticContentHtml key={data.id} presentationId={presentationId} staticContent={data} />;
	}

	return null;
};
