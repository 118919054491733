import { BorderProps, FlexboxProps, Text } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../Card';

interface Props {
	icon?: React.ReactNode;
	label: string;
	onClick?: () => void;
	description?: string;
}

export const BoxButton: React.FC<Props & FlexboxProps & BorderProps> = ({ icon, label, onClick, description, ...props }) => {
	return (
		<Card
			h={250}
			w={250}
			backgroundColor='white'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			_hover={{ cursor: 'pointer', boxShadow: '2xl' }}
			onClick={onClick}
			boxShadow='lg'
			{...props}
		>
			{icon ? icon : null}
			<Text fontSize='3xl' mt={2} lineHeight='2.2rem'>
				{label}
			</Text>
			{description ? (
				<Text fontSize='xl' align='center'>
					{description}
				</Text>
			) : null}
		</Card>
	);
};
