import ApiClient from '../../../apiClient';
import { StaticContentT } from '../../../types/StaticContent.ts';

export const getStaticContentDetails = async (presentationId: string, staticContentId: string): Promise<StaticContentT> => {
	return (await ApiClient.getInstance().get<StaticContentT>(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`)).data;
};

export type UpdateStaticContentInput = Pick<StaticContentT, 'title' | 'description' | 'content'>;
export const saveStaticContent = async (presentationId: string, staticContentId: string, input: UpdateStaticContentInput): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`, input)).data;
};

export const deleteStaticContent = async (presentationId: string, staticContentId: string): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`);
};
