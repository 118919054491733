import { BasicSlideT, ElementTypeT } from '../../../../types/Presentation.ts';

interface PresentationGridStyle {
	templateAreas: string;
	templateColumns: string;
	shoulDisplayPreview: boolean;
	isStaticContentSlide: boolean;
}

export const getPresentationGridStyle = (selectedSlide?: BasicSlideT): PresentationGridStyle => {
	const isStaticContentSlide = selectedSlide?.element.elementType === ElementTypeT.STATIC_CONTENT;
	return {
		templateAreas: isStaticContentSlide
			? `"header header"
			 "nav configuration"`
			: `"header header header"
			"nav main configuration"`,
		templateColumns: isStaticContentSlide ? '1fr 10fr' : '1fr 6fr 4fr',
		shoulDisplayPreview: !isStaticContentSlide,
		isStaticContentSlide,
	};
};
