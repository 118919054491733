import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { refreshElementPreview } from '../../presentation/components/ElementPreview/ElementPreview.tsx';
import { getStaticContentDetails, saveStaticContent, UpdateStaticContentInput } from '../service/StaticContentService.ts';

const key = (presentationId: string, staticContentId: string) => ['presentation', presentationId, 'static-content', staticContentId];

export const useStaticContent = (presentationId: string, staticContentId: string) => {
	return useQuery({
		queryKey: key(presentationId, staticContentId),
		queryFn: () => getStaticContentDetails(presentationId, staticContentId),
		refetchOnWindowFocus: false,
	});
};

export const useUpdateStaticContent = (presentationId: string, staticContentId: string) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (input: UpdateStaticContentInput) => saveStaticContent(presentationId, staticContentId, input),
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: key(presentationId, staticContentId) });
			refreshElementPreview();
		},
	});
};
