import React from 'react';
import { updatePresentationTitle } from '../../service/PresentationService.ts';
import { AutoSaveInput } from '../../../commons/components/AutoSaveInput';
import { useInvalidatePresentationQuery } from '../../hooks/usePresentation.ts';

interface Props {
	presentationId: string;
	currentTitle: string;
}

export const PresentationTitle: React.FC<Props> = ({ presentationId, currentTitle }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const handleOnSave = async (data: string) => {
		await updatePresentationTitle(presentationId, data);
		await invalidatePresentation();
	};

	return <AutoSaveInput id={presentationId} currentValue={currentTitle} onSave={handleOnSave} />;
};
