import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { InputBasic } from '../InputBasic';

interface Props {
	id: string;
	currentValue: string;
	onSave: (value: string) => void;
}

interface FormValue {
	value: string;
}

export const AutoSaveInput: React.FC<Props> = ({ id, currentValue, onSave }) => {
	const { register, handleSubmit, watch } = useForm<FormValue>({
		defaultValues: {
			value: currentValue,
		},
	});

	const debouncedSave = useMemo(
		() =>
			debounce(async (data: FormValue) => {
				return onSave(data.value);
			}, 750),
		[onSave]
	);

	const onSubmit: SubmitHandler<FormValue> = useCallback(
		async (data) => {
			await debouncedSave(data);
		},
		[debouncedSave]
	);

	useEffect(() => {
		const subscription = watch(() => {
			handleSubmit(onSubmit)();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, handleSubmit, onSubmit]);

	return (
		<InputBasic
			id={id}
			title={watch('value')}
			placeholder=''
			required
			{...register('value', {
				required: true,
				minLength: 1,
			})}
		/>
	);
};
