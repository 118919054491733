import { Box, Container, Divider, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { getClienAppSlideUrlPreview } from '../../service/LinkService.ts';
import { BasicPresentationT, BasicSlideT } from '../../../../types/Presentation.ts';
import { translate } from '../../../../translate';

interface Props {
	presentation?: BasicPresentationT;
	slide?: BasicSlideT;
}

export const ElementPreview: React.FC<Props> = ({ presentation, slide }) => {
	return (
		<div style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
			<Box bg='bg.surface'>
				<Container py={{ base: '2', md: '4' }}>
					<HStack>
						<Divider />
						<Text textStyle='md' fontWeight='medium' whiteSpace='nowrap'>
							{translate('Slide Preview (View only)')}
						</Text>
						<Divider />
					</HStack>
				</Container>
			</Box>
			<iframe
				id={iframeId}
				title={iframeId}
				autoFocus={false}
				tabIndex={-1}
				width='100%'
				height='93.5%'
				src={getClienAppSlideUrlPreview(presentation?.id, slide?.id)}
				style={{ border: 'none', borderRadius: '6px' }}
			></iframe>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 1,
				}}
			></div>
		</div>
	);
};

const iframeId = 'previewIframe';

export function refreshElementPreview() {
	const element = document.getElementById(iframeId) as HTMLIFrameElement | null;
	if (element) {
		element.src += '';
	}
}
