import {
	Alert,
	AlertTitle,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPresentation } from '../../service/PresentationService.ts';
import { HiPlus } from 'react-icons/hi';
import { SubmitHandler, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { translate } from '../../../../translate';

type FormValues = {
	presentationTitle: string;
};

export const CreatePresentationButton: React.FC = () => {
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		defaultValues: {
			presentationTitle: '',
		},
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		try {
			setError('');
			setIsLoading(true);
			const newPresentation = await createPresentation(data.presentationTitle, '');
			navigate(`/presentations/${newPresentation.id}`);
		} catch (e) {
			setError('Error when creating presentation');
		} finally {
			setIsLoading(false);
		}
	};

	const handleOnClose = () => {
		reset();
		onClose();
	};

	return (
		<>
			<Button onClick={onOpen} rightIcon={<HiPlus />} isLoading={isLoading} variant='submit'>
				{translate('New presentation')}
			</Button>
			<Modal isOpen={isOpen} onClose={handleOnClose} size='full' isCentered>
				<ModalOverlay />
				<ModalContent as={Flex} flexDirection='column' justifyContent='center' alignItems='center'>
					<Flex flexDirection='column' width='80%'>
						<ModalHeader as={'h1'} fontSize='3xl' textAlign='center'>
							{translate('Presentation title')}
						</ModalHeader>
						{!isEmpty(error) ? (
							<Alert status='error' my={4}>
								<AlertTitle>{error}</AlertTitle>
							</Alert>
						) : null}
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Flex as='form' flexDirection='column' onSubmit={handleSubmit(onSubmit)}>
								<FormControl isInvalid={!!errors.presentationTitle}>
									<Input
										id='presentationTitle'
										placeholder={translate('Type your presentation title here')}
										size='xl'
										autoComplete='off'
										{...register('presentationTitle', {
											required: 'This field is required',
											minLength: {
												value: 3,
												message: 'Your title must be at least 3 characters long',
											},
										})}
										autoFocus={true}
									/>
									{errors.presentationTitle ? <FormErrorMessage>{errors.presentationTitle?.message}</FormErrorMessage> : null}
								</FormControl>
								<Flex alignSelf='center'>
									<Button variant='submit' type='submit' disabled={isLoading} isLoading={isLoading}>
										{translate('Create')}
									</Button>
								</Flex>
							</Flex>
						</ModalBody>
					</Flex>
				</ModalContent>
			</Modal>
		</>
	);
};
