import { useQuery } from '@tanstack/react-query';
import { getThemeByPresentationId } from '../service/ThemingService.ts';

const key = (presentationId: string) => ['theme', 'presentation', presentationId];

export const useThemeForPresentation = (presentationId: string) => {
	return useQuery({
		queryKey: key(presentationId),
		queryFn: () => getThemeByPresentationId(presentationId),
		refetchOnWindowFocus: false,
	});
};
