import React, { useState } from 'react';
import {
	Button,
	Flex,
	Icon,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PresentationListT } from '../../../../../../types/Presentation.ts';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineExport } from 'react-icons/ai';
import { deletePresentation, duplicatePresentation } from '../../../../service/PresentationService.ts';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { openPresentationInClientApp } from '../../../../service/LinkService.ts';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { translate } from '../../../../../../translate';

interface Props {
	presentations: PresentationListT[];
	onAfterDeletion: () => void;
}

export const PresentationListTable: React.FC<Props & TableProps> = ({ presentations, onAfterDeletion }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const [presentationToDelete, setPresentationToDelete] = useState<PresentationListT>();

	const handleOnDelete = (presentationId: string) => () => {
		const selectedPresentation = find(presentations, { id: presentationId });
		setPresentationToDelete(selectedPresentation);
		onOpen();
	};

	const handleDelete = async () => {
		if (presentationToDelete) {
			await deletePresentation(presentationToDelete.id);
			onAfterDeletion();
			onClose();
		}
	};

	const duplicate = (presentationId: string) => async () => {
		const newPresentation = await duplicatePresentation(presentationId);
		navigate(`/presentations/${newPresentation.presentationId}`);
	};

	return (
		<>
			{isEmpty(presentations) ? (
				<Flex flexDirection='column' alignItems='center'>
					<Text fontSize='xl' fontWeight='bold'>
						There aren&apos;t any Presentations
					</Text>
				</Flex>
			) : null}
			{!isEmpty(presentations) ? (
				<>
					<TableContainer
						width='full'
						maxH='74vh'
						overflowY='auto'
						sx={{
							'::-webkit-scrollbar': {
								display: 'none',
							},
						}}
					>
						<Table>
							<Thead>
								<Tr>
									<Th>{translate('Title')}</Th>
									<Th>{translate('Last modified')}</Th>
									<Th>{translate('Actions')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{presentations.map((presentation) => (
									<Tr key={presentation.id}>
										<Td>
											<Link as={RouterLink} to={presentation.id}>
												<Text fontSize='md'>{presentation.title}</Text>
											</Link>
										</Td>
										<Td>
											<Link as={RouterLink} to={presentation.id}>
												<Text fontSize='md'>{presentation.lastModifiedDate.toString()}</Text>
											</Link>
										</Td>
										<Td>
											<Menu variant='simple'>
												<MenuButton>
													<Icon as={BiDotsHorizontalRounded} fontSize='xl' />
												</MenuButton>
												<MenuList fontSize={18}>
													<MenuItem icon={<AiOutlineExport />} onClick={() => openPresentationInClientApp(presentation.id)}>
														{translate('Play presentation')}
													</MenuItem>
													<MenuItem as={RouterLink} to={presentation.id} icon={<AiOutlineEdit />}>
														{translate('Edit')}
													</MenuItem>
													<MenuItem icon={<AiOutlineEdit />} onClick={duplicate(presentation.id)}>
														{translate('Duplicate')}
													</MenuItem>
													<MenuItem onClick={handleOnDelete(presentation.id)} icon={<AiOutlineDelete />} color='red'>
														{translate('Delete')}
													</MenuItem>
												</MenuList>
											</Menu>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
					<Modal isOpen={isOpen} size='xl' isCentered onClose={onClose}>
						<ModalOverlay />
						<ModalContent p={8}>
							<ModalCloseButton />
							<ModalHeader>
								<Text fontSize='2xl' textAlign='center'>
									Deleting: {presentationToDelete?.title}
								</Text>
							</ModalHeader>
							<ModalBody m={0} p={0} textAlign='center' as={Flex} justifyContent='center' alignItems='center'>
								<Text m={0} pt={0} fontSize='lg'>
									{translate('Are you sure?')}
								</Text>
							</ModalBody>
							<ModalFooter my={2} justifyContent='space-around'>
								<Button onClick={handleDelete} variant='submit'>
									{translate('Yes')}
								</Button>
								<Button onClick={onClose} variant='submit'>
									{translate('No')}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</>
			) : null}
		</>
	);
};
