import React, { useState } from 'react';
import { VisualizationTypeT } from '../../../../../../../../types/Visualization.ts';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { VisualizationIconBox } from '../../../VisualizationIconBox';
import isEmpty from 'lodash/isEmpty';
import { NewVisualizationInput } from './_/NewVisualizationInput';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { translate } from '../../../../../../../../translate';

export interface NewVisualizationValues {
	title: string;
	type: VisualizationTypeT;
}

interface Props {
	supportedVisualizations: VisualizationTypeT[];
	showTitle: boolean;
	onSelectedVisualization: (newVisualization: NewVisualizationValues) => void;
	onGoBack: () => void;
}

export const NewVisualization: React.FC<Props> = ({ supportedVisualizations, onSelectedVisualization, showTitle, onGoBack }) => {
	const [selectedVisualizationType, setSelectedVisualizationType] = useState<VisualizationTypeT | undefined>();
	const [newVisualizationTitle, setNewVisualizationTitle] = useState('');

	const handleOnGoBack = () => {
		setSelectedVisualizationType(undefined);
		setNewVisualizationTitle('');
	};

	const handleSubmit = (visualizationTitle: string) => {
		setNewVisualizationTitle(visualizationTitle);
		if (selectedVisualizationType && !isEmpty(visualizationTitle)) {
			onSelectedVisualization({
				title: visualizationTitle,
				type: selectedVisualizationType,
			});
		}
	};

	return (
		<>
			{!selectedVisualizationType ? (
				<>
					{showTitle ? <NewElementHeader title={translate('What type of visualization do you want to create?')} onGoBack={onGoBack} /> : null}
					<Flex justifyContent='center' alignItems='center' textAlign='center'>
						<SimpleGrid columns={4} gap={8}>
							{supportedVisualizations.map((visualization) => (
								<VisualizationIconBox key={visualization} visualizationType={visualization} onClick={setSelectedVisualizationType} />
							))}
						</SimpleGrid>
					</Flex>
				</>
			) : null}
			{selectedVisualizationType && isEmpty(newVisualizationTitle) ? (
				<>
					{showTitle ? <NewElementHeader title={translate('Visualization title')} onGoBack={handleOnGoBack} /> : null}
					<NewVisualizationInput onTitleSubmit={handleSubmit} />
				</>
			) : null}
		</>
	);
};
