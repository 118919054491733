import React, { useEffect } from 'react';
import { StaticContentT } from '../../../../types/StaticContent.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { UpdateStaticContentInput } from '../../service/StaticContentService.ts';
import { TipTap } from '../../../tiptap/TipTap';
import { InputTitle } from '../../../commons/components/InputTitle';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
	staticContent: StaticContentT;
}

export const StaticContentHtml: React.FC<Props> = ({ presentationId, staticContent }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateStaticContent = useUpdateStaticContent(presentationId, staticContent.id);
	const { register, handleSubmit, watch, control } = useForm<UpdateStaticContentInput>({
		defaultValues: {
			title: staticContent.title,
			description: staticContent.description,
			content: staticContent.content,
		},
	});

	const onSubmit: SubmitHandler<UpdateStaticContentInput> = async (data) => {
		console.log({ data });
		updateStaticContent.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			void invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={staticContent} elementType={ElementTypeT.STATIC_CONTENT} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<Flex flexDirection='column' gap={4}>
								<InputTitle
									title={watch('title')}
									{...register('title', {
										maxLength: INPUT_TITLE_MAX_LENGHT,
										required: true,
									})}
								/>
								<Controller
									control={control}
									name='content'
									render={({ field: { onChange, value } }) => {
										return <TipTap htmlContent={value} onChange={onChange} />;
									}}
								/>
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={staticContent.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
