import { Editor } from '@tiptap/react';
import { INPUT_DESCRIPTION_MAX_LENGHT } from '../../../types/Constants.ts';

interface Props {
	editor: Editor;
}

export const TipTapEditorStats = ({ editor }: Props) => {
	return (
		<div className='character-count'>
			{editor?.storage.characterCount.characters()}/{INPUT_DESCRIPTION_MAX_LENGHT} characters . {editor?.storage.characterCount.words()} words
		</div>
	);
};
