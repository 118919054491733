import ApiClient from '../../../apiClient';
import { ThemeT } from '../model/ThemeT.ts';

export interface ThemeItem {
	id: ThemeT['id'];
	label: ThemeT['label'];
}

export const getAllThemes = async (): Promise<ThemeItem[]> => {
	return (await ApiClient.getInstance().get<ThemeItem[]>(`/author-app/themes`)).data;
};

//==========================================

export const upsertTheme = async (input: ThemeT): Promise<void> => {
	input.id ? await updateTheme(input) : await createTheme(input);
};

const updateTheme = async (input: ThemeT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/themes/${input.id}`, input);
};

const createTheme = async (input: ThemeT): Promise<void> => {
	await ApiClient.getInstance().post(`/author-app/themes`, input);
};

//=============================

export const getThemeById = async (themeId: ThemeT['id']): Promise<ThemeT> => {
	return (await ApiClient.getInstance().get<ThemeT>(`/author-app/themes/${themeId}`)).data;
};

export const getThemeByPresentationId = async (presentationId: string): Promise<ThemeT> => {
	return (await ApiClient.getInstance().get<ThemeT>(`/author-app/themes/presentations/${presentationId}`)).data;
};

export const assignThemeToPresentation = async (themeId: ThemeT['id'], presentationId: string): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/themes/assign-theme`, { themeId, presentationId });
};

export const unassignThemeToPresentation = async (presentationId: string): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/themes/unassign-theme`, { presentationId });
};

export const deleteThemeById = async (themeId: ThemeT['id']): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/themes/${themeId}`);
};
