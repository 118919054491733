import React from 'react';
import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { translate } from '../../../../../../../../../../translate';

interface Props {
	onTitleSubmit: (visualizationTitle: string) => void;
}

type FormValues = {
	visualizationTitle: string;
};

export const NewVisualizationInput: React.FC<Props> = ({ onTitleSubmit }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		shouldUnregister: true,
		defaultValues: {
			visualizationTitle: '',
		},
	});

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		onTitleSubmit(data.visualizationTitle);
		reset();
	};

	return (
		<Flex flexDirection='column' width='80%'>
			<Flex as='form' flexDirection='column' onSubmit={handleSubmit(onSubmit)}>
				<FormControl isInvalid={!!errors.visualizationTitle}>
					<Input
						id='visualizationTitle'
						placeholder={translate('Type your visualization title here')}
						size='xl'
						autoComplete='off'
						{...register('visualizationTitle', {
							required: 'This field is required',
							minLength: {
								value: 3,
								message: 'Your title must be at least 3 characters long',
							},
						})}
						autoFocus={true}
					/>
					{errors.visualizationTitle ? <FormErrorMessage>{errors.visualizationTitle?.message}</FormErrorMessage> : null}
				</FormControl>
				<Flex alignSelf='center'>
					<Button variant='submit' type='submit'>
						Next
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
