import { useQuery } from '@tanstack/react-query';
import { BasicPresentationT } from '../../../types/Presentation.ts';
import { InteractionT } from '../../../types/Interaction.ts';
import { searchEntries, SearchEntriesInput } from '../service/EntryService.ts';

const key = (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: SearchEntriesInput) => ['entry-list', presentationId, interactionId, input];

export const useEntryList = (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: SearchEntriesInput) => {
	return useQuery({
		queryKey: key(presentationId, interactionId, input),
		queryFn: () => searchEntries(presentationId, interactionId, input),
		refetchOnWindowFocus: false,
		staleTime: 30000,
	});
};
