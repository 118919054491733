import React from 'react';
import { getClientAppPresentationUrl } from '../../service/LinkService.ts';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useClipboard, useDisclosure } from '@chakra-ui/react';
import { translate } from '../../../../translate';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { MdCode } from 'react-icons/md';

interface Props {
	presentationId: BasicPresentationT['id'];
	presentationTitle: BasicPresentationT['title'];
}

export const EmbedPresentation: React.FC<Props> = ({ presentationId, presentationTitle }) => {
	const iframeString: string = `<div><iframe title='${presentationTitle}' src='${getClientAppPresentationUrl(presentationId)}' width='100%' height='750'></iframe><div style='text-align: center; margin-top: 4px;'><a href='https://questiory.com' target='_blank' style='font-size: 14px; color: #000; text-decoration: none; opacity: 0.2;' onmouseover='this.style.opacity=1;' onmouseout='this.style.opacity=0.4;' title='Questiory - Collaborative presentations that engage'>Created with Questiory</a></div></div>`;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onCopy, value, hasCopied, setValue } = useClipboard(iframeString);

	return (
		<>
			<Button rightIcon={<MdCode />} variant='ghost' size='lg' onClick={onOpen} px={5}>
				{translate('Insert')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{translate('Embed this presentation')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Textarea value={value} disabled size='xl' rows={10} onChange={() => setValue(iframeString)}>
							{iframeString}
						</Textarea>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onCopy} variant='submit'>
							{hasCopied ? translate('Copied') : translate('Copy')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
