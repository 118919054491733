import React, { useState } from 'react';
import { usePresentationId } from '../../../presentation/hooks/usePresentationId.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useNavigate } from 'react-router-dom';
import { createStaticContent } from '../../../presentation/service/ElementService.ts';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';
import { IconBox } from '../../../commons/components/IconBox';
import { translate } from '../../../../translate';

interface Props {}

export const StaticContentSelector: React.FC<Props> = () => {
	const presentationId = usePresentationId();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnCreate = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const newSlide = await createStaticContent(presentationId, StaticContentTypeT.HTML);
			await invalidatePresentationQuery();
			navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
		}
	};

	return (
		<IconBox
			iconImageSrc='/assets/brand/icons/TELL.svg'
			label={translate('Tell')}
			description={!isLoading ? translate('Tell something to your audience') : translate('Creating')}
			onClick={handleOnCreate}
		/>
	);
};
