import { Box, Flex, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useAuth } from '../../../auth/FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import { MdLogout } from 'react-icons/md';
import { useRemovePresentationList } from '../../hooks/usePresentationList.ts';

interface Props {}

export const DefaultMenu: React.FC<Props> = () => {
	const { currentUser, logout } = useAuth();
	const removePresentationList = useRemovePresentationList();
	const handleLogout = async () => {
		removePresentationList();
		await logout();
	};

	return (
		<Box as='nav' w='100%' boxShadow={useColorModeValue('xxs', 'xxs-dark')}>
			<Flex flexDir='row' justifyContent='flex-end' p={2} alignItems='center'>
				<Text>{currentUser?.email}</Text>
				<IconButton aria-label='logout' icon={<MdLogout />} color='brand' m={0} onClick={handleLogout} />
			</Flex>
		</Box>
	);
};
