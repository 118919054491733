import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import '../assets/styles.css';
import { CharacterCount } from '@tiptap/extension-character-count';
import { Image } from '@tiptap/extension-image';
import { INPUT_DESCRIPTION_MAX_LENGHT } from '../../../types/Constants.ts';
import { Link } from '@tiptap/extension-link';
import { TextAlign } from '@tiptap/extension-text-align';
import { Box, Flex } from '@chakra-ui/react';
import { TipTapMenuItems } from './TipTapMenuItems.tsx';
import { TipTapEditorStats } from './TipTapEditorStats.tsx';

const extensions = [
	TextAlign.configure({
		types: ['heading', 'paragraph'],
	}),
	CharacterCount.configure({
		limit: INPUT_DESCRIPTION_MAX_LENGHT,
	}),
	Link.configure({
		autolink: false,
		HTMLAttributes: {
			class: 'static-content-html-link',
			rel: 'noopener noreferrer',
			target: '_blank',
		},
	}),
	Image.configure({
		inline: true,
	}),
	StarterKit.configure({
		bulletList: {
			keepMarks: true,
			keepAttributes: false, // Check the official docs: Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
		orderedList: {
			keepMarks: true,
			keepAttributes: false, // Check the official docs: Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
	}),
];

interface Props {
	htmlContent: string;
	onChange: (newHtmlContent: string) => void;
}

/**
 * Links
 * 	- https://tiptap.dev/docs/editor/installation/react
 *  - https://tiptap.dev/docs/editor/guide/output#listening-for-changes
 */
export const TipTap: React.FC<Props> = ({ htmlContent, onChange }) => {
	const editor = useEditor({
		content: htmlContent,
		extensions: extensions,
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		onUpdate: (props: any) => {
			const editorContent = props.editor.getHTML();
			// setContent(editorContent);
			onChange(editorContent);
		},
		editorProps: {
			attributes: {
				class: 'editor-props',
			},
		},
	});

	if (!editor) {
		return <span>No Tip tap editor</span>;
	}

	return (
		<Flex flexDirection='column' gap={2}>
			<Flex flexDirection='column' gap={2} alignItems='center'>
				<TipTapMenuItems editor={editor} />
				<TipTapEditorStats editor={editor} />
			</Flex>
			<Box id='content-editor' maxHeight='xl' overflowY='auto'>
				<EditorContent editor={editor} content={htmlContent} />
			</Box>
		</Flex>
	);
};
