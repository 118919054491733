import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPresentationList } from '../service/PresentationService.ts';

const key = (search: string) => ['presentation-list', search];

export const usePresentationList = (search: string) => {
	return useQuery({
		queryKey: key(search),
		queryFn: () => getPresentationList(search),
		refetchOnWindowFocus: false,
		staleTime: 30000,
	});
};

export const useRemovePresentationList = () => {
	const queryClient = useQueryClient();
	return () =>
		queryClient.removeQueries({
			queryKey: ['presentation-list'],
			exact: false,
		});
};
