import { BorderProps, FlexboxProps, Image } from '@chakra-ui/react';
import React from 'react';
import { BoxButton } from '../BoxButton';

interface Props {
	iconImageSrc: string;
	label: string;
	onClick?: () => void;
	description?: string;
}

export const IconBox: React.FC<Props & FlexboxProps & BorderProps> = ({ iconImageSrc, label, description, ...props }) => {
	return <BoxButton borderRadius='24' label={label} icon={<Image src={iconImageSrc} alt={label} boxSize='32' />} description={description} {...props} />;
};

IconBox.displayName = 'IconBox';
